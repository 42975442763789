'use client'

import React, { ReactNode } from 'react'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import LoadingSpinner from '@/components/atoms/LoadingSpinner/LoadingSpinner'
import LoadingSpinnerContainer from '@/components/atoms/LoadingSpinner/LoadingSpinnerContainer'

interface AsyncLDProviderProps {
  children: ReactNode
  userPayload: {
    user: {
      id: number // Parsed from userId
      email: string // Parsed from userEmail
    }
  } | null
}

let LDProvider: React.ComponentType<{ children: ReactNode }> | null = null

async function initializeLDProvider(
  clientSideID: string,
  userPayload: AsyncLDProviderProps['userPayload']
) {
  if (!LDProvider) {
    LDProvider = await asyncWithLDProvider({
      clientSideID,
      context: {
        kind: 'user',
        key: userPayload?.user?.id?.toString() || 'anonymous-user',
        email: userPayload?.user?.email || undefined,
      },
    })
  }
}

export default function AsyncLDProvider({
  children,
  userPayload,
}: AsyncLDProviderProps) {
  const clientSideID =
    process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT === 'production'
      ? process.env.NEXT_PUBLIC_LD_CLIENT_SIDE_ID_PROD
      : process.env.NEXT_PUBLIC_LD_CLIENT_SIDE_ID_TEST

  if (!LDProvider && clientSideID) {
    throw initializeLDProvider(clientSideID, userPayload)
  }

  if (!LDProvider) {
    return (
      <LoadingSpinnerContainer height="70vh">
        <LoadingSpinner size="5rem" />
      </LoadingSpinnerContainer>
    )
  }

  const Provider = LDProvider
  return <Provider>{children}</Provider>
}
