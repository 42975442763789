import { CSSProperties } from 'react'
import Box, { BoxProps } from '../Box/Box'

import styles from './LoadingSpinner.module.css'

type LoadingSpinnerContainerProps = BoxProps<'div'> & {
  height?: string
}

const LoadingSpinnerContainer = ({
  children,
  height = '100vh',
}: LoadingSpinnerContainerProps) => {
  const heightStyles = {
    '--height': height,
  }
  return (
    <Box style={heightStyles as CSSProperties} className={styles.pageContainer}>
      {children}
    </Box>
  )
}

export default LoadingSpinnerContainer
